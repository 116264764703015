import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { analytics } from '../config/firebase';
import { logEvent } from "firebase/analytics";


const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [premiumCode, setPremiumCode] = useState("");
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [error, setError] = useState("");
  const [premiumCodeError, setPremiumCodeError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = searchParams.get('p');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value.replace(/[^\d]/g, '');
    if (value.length <= 10) {
      setPhone(value);
    }
  };

  const handleEmailSignIn = async () => {
    try {
      const requestBody = {
        email: email,
        payment: params === 'payment'
      };

      if (phone.length === 10) {
        requestBody.phone_number = phone;
        localStorage.setItem('phone', phone);
      }

      if (premiumCode) {
        requestBody.premium_code = premiumCode;
      }

      const response = await fetch(`${API_BASE_URL}/auth/login/email`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();

      if (response.ok && responseData.response === "OK") {
        logEvent(analytics, "sign_in_link_generated", {
          category : "activation_rate",
          page : window.location.pathname
        });
        logEvent(analytics, "authentication_session", {
          category : "activation_rate",
          page : window.location.pathname,
          email: email,
          session_start_time: new Date().toISOString()
        });
      
        navigate("/code");
      } else {
        if (responseData.error) {
          setError(responseData.error);
        } else if (responseData.premium_code_error) {
          setPremiumCodeError("The code is invalid. Try a different code.");
        } else {
          setError(responseData.message || "Unknown error");
        }
      }
    } catch (error) {
      console.error("Error during sign-in:", error);
      setError("An error occurred during sign-in. Please try again.");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError("");
    setPremiumCodeError("");
    handleEmailSignIn();
  };

  const isButtonDisabled = !validateEmail(email) || !isTermsChecked || (phone.length > 0 && phone.length !== 10);

  return (
    <>
      <div className="chat-interface flex flex-col items-center w-full min-h-screen">
        <div className="chat-content w-full md:w-3/4 mx-auto flex flex-col">
          <div className="flex justify-between items-center w-full">
            <a href="https://behumane.ai" className="text-sm text-white hover:text-gray-300 p-4">behumane.ai</a>
            <a href="/" className="text-sm text-white hover:text-gray-300 p-4">Back to Chat</a>
          </div>
          <div className="mb-6 text-center">
            <h1 className="text-4xl font-bold text-white mb-2">Sign In</h1>
            <p className="text-sm lg:text-lg text-white">
              Be Humane is your safe space to share your thoughts, feelings, and questions.
            </p>
          </div>
          <div className="flex justify-center">
            <div className="w-full p-8 md:w-1/3 rounded-lg shadow-md">
              <main className="login-form">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-white">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="example@email.com"
                    className="mb-4 w-full p-2 text-gray-900 bg-white rounded"
                    required
                    style={{
                      borderRadius: "1rem",
                      border: "2px solid #609",
                      padding: "20px",
                      height: "15px",
                    }}
                  />
                  <label htmlFor="phone" className="block mb-2 text-sm font-medium text-white">
                    Phone (Optional)
                  </label>
                  <div className="flex items-center mb-2">
                    <span className="text-white mr-2">+1</span>
                    <input
                      type="tel"
                      id="phone"
                      value={phone}
                      onChange={handlePhoneChange}
                      placeholder="1234567890"
                      className="w-full p-2 text-gray-900 bg-white rounded"
                      style={{
                        borderRadius: "1rem",
                        border: "2px solid #609",
                        padding: "20px",
                        height: "15px",
                      }}
                    />
                  </div>
                  <label htmlFor="premiumCode" className="block mb-2 text-sm font-medium text-white">
                    Premium Code
                  </label>
                  <input
                    type="text"
                    id="premiumCode"
                    value={premiumCode}
                    onChange={(e) => setPremiumCode(e.target.value)}
                    placeholder="Enter 6-character code"
                    className="mb-2 w-full p-2 text-gray-900 bg-white rounded"
                    style={{
                      borderRadius: "1rem",
                      border: "2px solid #609",
                      padding: "20px",
                      height: "15px",
                    }}
                    maxLength={6}
                  />
                  {premiumCodeError && (
                    <p className="text-red-500 text-sm mb-2">{premiumCodeError}</p>
                  )}
                  <div className="checkbox mb-4">
                    <input
                      type="checkbox"
                      id="terms"
                      className="mr-2"
                      checked={isTermsChecked}
                      onChange={(e) => setIsTermsChecked(e.target.checked)}
                      required
                    />
                    <label htmlFor="terms" className="text-sm text-white">
                      I agree to Be Humane's &nbsp;
                      <a
                        href="https://www.behumane.ai/privacy-policy"
                        style={{
                          color: "white",
                          textDecoration: "underline",
                        }}
                        className="hover:text-blue-400"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Privacy Policy
                      </a>
                      &nbsp;and &nbsp;
                      <a
                        href="https://www.behumane.ai//terms-and-conditions"
                        style={{
                          color: "white",
                          textDecoration: "underline",
                        }}
                        className="hover:text-blue-400"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Terms and Conditions
                      </a>.
                    </label>
                  </div>
                  {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
                  <button
                    type="submit"
                    className={`w-full p-2 text-white ${isButtonDisabled ? "bg-gray-400" : "bg-custom-purple"}`}
                    style={{ borderRadius: "1.0rem" }}
                    disabled={isButtonDisabled}
                  >
                    Continue with email
                  </button>
                </form>
              </main>
            </div>
          </div>
        </div>
      </div>
      <div className="sticky-footer bg-gradient-to-b from-[#25164a] to-[#674088] p-2 w-full">
        <div className="flex justify-between items-center text-white text-sm">
          <a href="#help" className="hover:text-gray-300">
            Need help?
          </a>
        </div>
        <footer className="w-full text-center mt-auto">
          <p className="text-white text-sm opacity-75">
            © {new Date().getFullYear()} Be Humane, co.
          </p>
        </footer>
      </div>
    </>
  );
};

export default LoginPage;
