import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import ExpandIcon from '../images/expand-icon.png'
import ShareIcon from '../images/share-icon.png'
import LearnIcon from '../images/learn-icon.png'
import { analytics   } from '../config/firebase';
import { logEvent } from "firebase/analytics";

const Intro = () => {
    const navigate = useNavigate();
    useEffect(() => {
      if(localStorage.getItem("isShowWelcome") === "true"){
          navigate("/")
      }
      logEvent(analytics, "intro_page_view" , {page : window.location.pathname , category : "onboarding_pages" });
    },[navigate])
  return (
    <main className="pt-10 bg-chat-box-light p-10">
         <section className="container text-chat-purple">
            <div className="w-[100%] flex justify-center">
                <h1 className="text-5xl leading-15 lg:text-96 font-bold xl:leading-[96px] float-none md:float-start mb-5">How does it work?</h1>
            </div>
            <div className="h-[50px] xl:h-[90px] w-[100%]"></div>
            <article className="flex justify-center">
               <section className="xl:w-[26%]">
                  <div className="flex items-start">
                     <span className="bg-chat-purple w-[32px] h-[32px] flex justify-center rounded-full text-white text-[20px] shrink-0 mt-[5px]">1</span>
                     <div className="flex flex-col justify-start ml-4">
                     <h4 className="text-[36px] leading-[36px] font-medium flex items-center">Share <img src={ShareIcon} alt="" className="mt-1 ml-[14px]" /></h4>
                        <div className="h-[10px] w-[100%]"></div>
                        <p className="leading-5">your thoughts, questions, and challenges with Rhea</p>
                     </div>
                  </div>
                  <div className="h-[40px] xl:h-[40px] w-[100%]"></div>
                  <div className="flex items-start">
                     <span className="bg-chat-purple w-[32px] h-[32px] flex justify-center rounded-full text-white text-[20px] shrink-0 mt-[5px]">2</span>
                     <div className="flex flex-col justify-start ml-4">
                     <h4 className="text-[36px] leading-[36px] font-medium flex items-center">Expand <img src={ExpandIcon} alt="" className="mt-1 ml-[14px] xs:w-1/2 sm:w-auto" /></h4>
                        <div className="h-[10px] w-[100%]"></div>
                        <p className="leading-5">on the problem by responding to Rhea’s follow-up questions</p>
                     </div>
                  </div>
                  <div className="h-[40px] xl:h-[40px] w-[100%]"></div>
                  <div className="flex items-start">
                     <span className="bg-chat-purple w-[32px] h-[32px] flex justify-center rounded-full text-white text-[20px] shrink-0 mt-[5px]">3</span>
                     <div className="flex flex-col justify-start ml-4">
                     <h4 className="text-[36px] leading-[36px] font-medium flex items-center">Learn <img src={LearnIcon} alt="" className="mt-1 ml-[14px]"/></h4>
                        <div className="h-[10px] w-[100%]"></div>
                        <p className="leading-5">from Rhea’s personalized suggestions to start the healing process </p>
                     </div>
                  </div>
                  <div className="h-[40px] xl:h-[40px] w-[100%]"></div>
                  <p className="leading-5"><span className="font-semibold">Repeat the process </span>and provide more context so together, you find the peace you are looking for.</p>
                  <div className="h-[25px] w-[100%]"></div>
                  <span onClick={()=>{
                     localStorage.setItem('isShowWelcome',"true"); 
                     logEvent(analytics, "chat_page_view" , {page : window.location.pathname , category : "acquisition_rate" });
                     logEvent(analytics, "message_bar_activated", { page : window.location.pathname , category : "adoption_rate"});
                     navigate("/");
                  }} className="text-white text-[30px] bg-purple-to-pink rounded-[15px] text-center w-[100%] 
                        h-[64px] leading-[48px] font-bold flex justify-center items-center drop-shadow-4xl shadow-secondary/80">Let’s chat!</span>
                        <p className="text-[16px] mt-3 mb-8 xl:mb-0 text-center">and start your journey for FREE!</p>
               </section>
            </article>
         </section>
      </main>
  )
}

export default Intro