// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBX_ZcqvF1rYuwiGWiA6E0vHq9IusKj_LE",
  authDomain: "rhea-d4103.firebaseapp.com",
  projectId: "rhea-d4103",
  storageBucket: "rhea-d4103.firebasestorage.app",
  messagingSenderId: "830316348607",
  appId: "1:830316348607:web:31ae1e35529d73f212720a",
  measurementId: "G-GKLY2X98ST"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const analytics = getAnalytics(app);

export default app;