import React, { useRef, useEffect } from 'react';
import ReactGA from 'react-ga4';

const InputArea = ({ inputValue, onInputChange, onFormSubmit, isDisabled }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight);
    const maxLines = 6;
    const maxHeight = lineHeight * maxLines;

    const adjustHeight = () => {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;
    };

    adjustHeight(); // Adjust height initially
    textarea.focus(); // Focus the textarea when component mounts

    window.addEventListener('resize', adjustHeight);
    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, []); // Removed inputValue from dependency array to avoid focusing on input changes

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onFormSubmit(e);
    }
  };

  const handleFocus = () => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: 'User Interaction',
        action: 'message_bar_activated',
        label: 'Message Bar Activated'
      });
    }
  };

  return (
    <div className="bg-transparent flex items-end fix-input-box">
      <form onSubmit={onFormSubmit} className={`flex w-full rounded-2xl items-end p-3 ${isDisabled ? 'bg-[#FFFFFF0D]' : 'bg-white'}`}
        style={{
          boxShadow: isDisabled ? '0px 0px 10px 5px #643E8580' : 'none'
        }}>
        <textarea
          ref={textareaRef}
          placeholder="What's on your mind?"
          className="rounded-3xl flex-1 px-2 py-2 text-gray-700 focus:outline-none resize-none overflow-auto"
          value={inputValue}
          onChange={onInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          rows={1}
          disabled={isDisabled}
          style={{
            maxHeight: '144px', // Assuming each line height is 24px; adjust as needed
            background: isDisabled ? 'none' : 'iinherit'
          }} 
        />
        <button type="submit" className={`bg-purple-500 text-white rounded-xl font-semibold p-2 hover:bg-indigo-400 focus:bg-indigo-600 focus:outline-none ${isDisabled ? 'opacity-50 cursor-not-allowed  bg-[#FFFFFF0D]' : ''}`}
        disabled={isDisabled}  style={{background: isDisabled? '#FFFFFF0D' :'' }}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 10l7-7m0 0l7 7m-7-7v18" />
          </svg>
        </button>
      </form>
    </div>
  );
};

export default InputArea;
