
import React, { useState } from 'react';
import { useStripe, PaymentElement, useElements } from '@stripe/react-stripe-js';
import PaymentService from '../services/PaymentService';
import { useNavigate } from 'react-router-dom';
const CheckoutForm = ({ customerId, subscriptionType,premiumCode,clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [cardholderName, setCardholderName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState(null);
  const navigate = useNavigate();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) {
      return; // Ensure Stripe.js has loaded before proceeding
    }
  
    setIsLoading(true);
  
    let setupIntentResult;
    
    // Retrieve the SetupIntent status
    try {
      setupIntentResult = await stripe.retrieveSetupIntent(clientSecret);
    } catch (error) {
      handleStripeError(error);
      return;
    }
  
    // Check if SetupIntent already succeeded
    if (setupIntentResult?.setupIntent?.status === 'succeeded') {
      console.log("SetupIntent already succeeded, proceeding to create subscription...");
      await proceedToCreateSubscription(setupIntentResult.setupIntent.payment_method);
      return;
    }
  
    // If not succeeded, confirm the SetupIntent
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: cardholderName,
          },
        },
      },
      redirect: 'if_required',
    });
  
    if (error) {
      handleStripeError(error);
    } else {
      await proceedToCreateSubscription(setupIntent.payment_method);
    }
  };
  
  const proceedToCreateSubscription = async (paymentMethodId) => {
    try {
      const res = await PaymentService.createSubscription(customerId, subscriptionType, paymentMethodId,premiumCode);
      if (res && res.subscription_id) {
        console.log("Navigating to root after subscription creation");
        setIsLoading(false);
        localStorage.removeItem('customer_detail');
        navigate("/confirmed-plan");
      }
    } catch (err) {
      console.error("Subscription creation failed:", err);
      setIsLoading(false);
    }
  };
  
  const handleStripeError = (error) => {
    setIsLoading(false);
    if(error){
      const errorName = validateCardholderName(cardholderName);
          if (errorName) {
            setNameError(errorName);
            return;
          }
          if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
          } else {
            setMessage("An unexpected error occurred.");
          }
          setIsLoading(false);
    }
   
  };
  
  const paymentElementOptions = {
    layout: "tabs"
  }
  const validateCardholderName = (name) => {
    if (!name.trim()) {
      return "Cardholder name is required.";
    } else if (!/^[a-zA-Z\s]+$/.test(name)) {
      return "Cardholder name can only contain letters and spaces.";
    }
    return null; // No errors
  };
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} className='pt-5 fs-20 text-gray-600' />
      {message && <div id="payment-message text-[#df1b41] text-sm">{message}</div>}
      <div className="mb-4 mt-[10px]  text-grey-400 text-sm">
        <label htmlFor="cardholderName" className="block mb-[4px] text-gray-700">Cardholder Name</label>
        <input
          type="text"
          id="cardholderName"
          value={cardholderName}
          onChange={(e) => {
            setCardholderName(e.target.value);
            setNameError(null); // Reset error when input changes
          }}
          style={
            nameError !== null
              ? { boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px #df1b41" }
              : {}
          }
          placeholder="John Doe"
          className={
            "w-full border p-3 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 " +
            (nameError !== null ? "Input--invalid border-red-500 focus:ring-red-500 " : "") 
          }
        />
         {nameError && <p className="text-[#df1b41] text-sm mt-1">{nameError}</p>}
      </div>
      <button
        type='submit'
        disabled={!customerId && !isLoading}
        className="text-white bg-black p-2 w-full rounded mt-6 mb-10" >
        Subscribe {subscriptionType === "USD$50Y" ? " annually" : ' monthly'}
      </button>
    </form>
  );
};

export default CheckoutForm;

