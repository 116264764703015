import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import RheaIcon from '../images/rhea-happy.svg';
import zoranaImg from '../images/zorana.svg';
import austisImg from '../images/austis.svg';
import giannaImg from '../images/gianna.svg';
import natalieImg from '../images/natalie.svg';
import timothyImg from '../images/timothy.svg';
import arizonaImg from '../images/arizona.svg';
import stanfordImg from '../images/stanford.svg';
import universityImg from '../images/university.svg';
import costImg from '../images/cost.svg';
import expressImg from '../images/express.svg';
import mindImg from '../images/mind.svg';
import lineImg from '../images/line.png';
import yaleImg from '../images/yale.svg';
import { analytics   } from '../config/firebase';
import { logEvent } from "firebase/analytics";

const Welcome = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if(localStorage.getItem("isShowWelcome") === "true"){
        navigate("/")
    }
    logEvent(analytics, "welcome_page_view" , {page : window.location.pathname , category : "onboarding_pages" });
    const fetchSignInStatus = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/auth/status`,
          {
            credentials: "include",
          }
        );
      
        const data = await response.json();
        localStorage.setItem("chat_id", data.chat_id);
      };
  
      fetchSignInStatus();
  },[navigate])
  return (
    <main className="pt-10 bg-chat-box-light p-5">
    <section className="container text-chat-purple">
        <div className="flex flex-wrap justify-between">
            <article className="w-[100%] xl:w-[65%]">
                <section className="flex flex-wrap">
                    <div className="flex-1 shrink-0">
                        <h1 className="text-5xl leading-15 lg:text-96 font-bold xl:leading-[96px] float-none md:float-start mb-5">Always know <br className="hidden md:block" /> what to do</h1>
                        <img src={RheaIcon} className="max-w-[50%] md:max-w-[100%] float-right" alt=""/>
                        <p className="text-[28px] mt-8 leading-tight clear-left">Hello! My name is Rhea 👋 </p>
                        <p className="text-[28px] leading-[34px] mt-[90px] md:mt-8">I’m here to navigate you through <span className="font-bold">modern parenting challenges</span> powered by decades of expert knowledge.</p>
                    </div>
                    <div className="clear-both"></div>
                </section>
                <div className="h-[50px] w-[100%]"></div>
                <section className="inline-grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                    <div>
                        <img src={mindImg} className="mb-2 w-[40px] h-[40px]" alt=""/>
                        <p className="text-[23px] leading-[27px]"><span className="font-bold">Confidently make parenting decisions </span> 
                        grounded in our extensive expert advice</p>
                    </div>
                    <div>
                        <img src={expressImg} className="mb-2 w-[40px] h-[40px]" alt=""/>
                        <p className="text-[23px] leading-[27px]"><span className="font-bold">Express yourself openly and be heard</span> in a safe space without the fear of judgment</p>
                    </div>
                    <div>
                        <img src={costImg} className="mb-2 w-[40px] h-[40px]" alt=""/>
                        <p className="text-[23px] leading-[27px]"><span className="font-bold">Reduce crisis cost </span>by proactively addressing your parental questions and challenges 24/7</p>
                    </div>
                </section>
                <div className="h-[50px] xl:h-[90px] w-[100%]"></div>
                <section className="flex flex-col xl:grid place-content-center text-center">
                    <span onClick={()=>{navigate("/intro")}} className="text-white text-[30px] bg-purple-to-pink rounded-[15px] text-center w-[80%] xl:w-[528px] 
                    h-[70px] xl:h-[85px] leading-[48px] font-bold flex justify-center items-center drop-shadow-4xl shadow-secondary/80 fixed xl:static bottom-5 mx-auto left-0 right-0">Sounds good!</span>
                    {/* <p className="text-[20px] mt-4 mb-8 xl:mb-0">and start your journey for FREE!</p> */}
                </section>
            </article>
            <div className="devider hidden xl:block">
                <img src={lineImg} alt=""/>
            </div>
            <aside className="w-[100%] xl:w-[25%]">
                <section>
                    <h3 className="mb-4 text-[24px] font-bold">Our Scientific Commitee</h3>
                    <ul className="inline-grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-1 gap-5">
                        <li className="inline-flex items-center mb-3">
                            <img src={zoranaImg} className="w-[67px] h-[67px] rounded-full object-cover" alt=""/>
                            <div className="user-info ms-3">
                                <h3 className="font-bold text-[18px] mb-0.5">Zorana I. Pringle, PhD.</h3>
                                <p className="text-[14px]">Yale Center for Emotional Inteligence</p>  
                            </div>
                        </li>
                        <li className="inline-flex items-center mb-3">
                            <img src={austisImg} className="w-[67px] h-[67px] rounded-full object-cover" alt=""/>
                            <div className="user-info ms-3">
                                <h3 className="font-bold text-[18px] mb-0.5">Dr. R. Austin Heafey, PsyD</h3>
                                <p className="text-[14px]">Unfold Psychology</p>  
                            </div>
                        </li>
                        <li className="inline-flex items-center mb-3">
                            <img src={natalieImg} className="w-[67px] h-[67px] rounded-full object-cover" alt=""/>
                            <div className="user-info ms-3">
                                <h3 className="font-bold text-[18px] mb-0.5">Natalie Eggum, PhD.</h3>
                                <p className="text-[14px]">Arizona State University</p>  
                            </div>
                        </li>
                        <li className="inline-flex items-center mb-3">
                            <img src={timothyImg} className="w-[67px] h-[67px] rounded-full object-cover" alt=""/>
                            <div className="user-info ms-3">
                                <h3 className="font-bold text-[18px] mb-0.5">Timothy Cavell, PhD. prof.</h3>
                                <p className="text-[14px]">University of Arkansas</p>  
                            </div>
                        </li>
                        <li className="inline-flex items-center mb-3">
                            <img src={giannaImg} className="w-[67px] h-[67px] rounded-full object-cover" alt=""/>
                            <div className="user-info ms-3">
                                <h3 className="font-bold text-[18px] mb-0.5">Gianna Grandinetti, LSW, MSW </h3>
                                <p className="text-[14px]">Mental Health Clinic</p>  
                            </div>
                        </li>
                    </ul>
                </section>
                <div className="h-[50px] w-[100%]"></div>
                <section>
                    <ul class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-1 gap-4">
                        <li className="flex justify-center mb-4">
                            <img src={yaleImg} className="max-w-[300px]" alt=""/>
                        </li>
                        <li className="flex justify-center mb-4">
                            <img src={stanfordImg} className="max-w-[300px]" alt=""/>
                        </li>
                        <li className="flex justify-center mb-4">
                            <img src={arizonaImg} className="max-w-[300px]" alt=""/>
                        </li>
                        <li className="flex justify-center mb-4">
                            <img src={universityImg} className="max-w-[300px]" alt=""/>
                        </li>
                    </ul>
                </section>
                <p className="text-center text-[13px] pt-[50px] pb-[70px] block xl:hidden">©2024 Be Humane, co. </p>
            </aside>
        </div>
    </section>
</main> 
  )
}

export default Welcome