import React, { useEffect, useState } from 'react'
import PaymentService from '../services/PaymentService';
import UserService from '../services/UserService';
import { useNavigate } from 'react-router-dom';
import Button from './Button';

const ConfirmedPlan = () => {
    const navigate = useNavigate();
    const [isSubscriptionDetail, setSubscriptionDetail] = useState(null);
    let data = JSON.parse(localStorage.getItem("isSignedIn"))
    const [subscription_id] = useState(data.subscription_id);

    useEffect(() => {
        const fetchSignInStatus = async () => {
            const data = await UserService.getSignInStatus()
            localStorage.setItem("isSignedIn", JSON.stringify(data))
            const response = await PaymentService.getSubscriptionDetails(data.subscription_id)
            setSubscriptionDetail(response)
        };

        fetchSignInStatus();
    }, [subscription_id])
    return (
        <div className='bg-chat-box-light min-h-screen w-screen'>
            <div className="flex py-11 px-6">
                <span className="text-xs p-4 text-chat-purple"></span>
            </div>
            <div className="mx-auto flex flex-col item-start sm:px-9 md:items-center">
                <p className="font-semibold text-4xl text-chat-purple pl-8 sm:p-0 md:p-0">Confirmed!</p>
                <p className="text-base/8 font-normal text-chat-purple pl-8 sm:p-0 md:p-0">You can now take full advantage of Be Human(e)’s services 🙌</p>
                <div className='md:border rounded-2xl border-custom-purple flex flex-col content-center md:mt-8 mb-10 max-md:mb-4 p-8'>
                    <div className='flex flex-col mb-7 justify-between sm:flex-row md:flex-row'>
                        <div className="text-xs text-chat-purple mt-3 sm:mt-0">Amount Paid <br /><p className='font-bold mt-2'>{isSubscriptionDetail && isSubscriptionDetail.amount_paid}</p></div>
                        <div className="text-xs  text-chat-purple mt-3 sm:mt-0">Date Paid<br /><p className='font-bold mt-2'>{isSubscriptionDetail && isSubscriptionDetail.date_paid}</p></div>
                        <div className="text-xs text-chat-purple mt-3 sm:mt-0">Payment Method<br /><p className='font-bold mt-2'>{isSubscriptionDetail && isSubscriptionDetail.payment_method}</p></div>
                    </div>
                    <div>
                        <p className='font-normal text-chat-purple text-xs mb-1'>Summary</p>
                        <div className="bg-light-gray  h-20 p-3 flex flex-row justify-between">
                            <div className="text-xs p-2 text-chat-purple" >
                                RHEA Plus <br /><p className='italic mt-2'>Billed {isSubscriptionDetail && isSubscriptionDetail.billing_cycle}</p>
                            </div>
                            <div className="text-xs p-4 text-chat-purple">
                                <p>{isSubscriptionDetail && isSubscriptionDetail.amount_paid}</p>
                            </div>
                        </div>
                        <div className='mb-2 mt-5 text-chat-purple'><p className='text-xs '>Next Bill Date: <b>{isSubscriptionDetail && isSubscriptionDetail.next_billing_date}</b></p></div>
                        <div className='mt-5'><p className='text-xs '>If you have any questions, contact us at <a href="mailto:support@behumane.co" className="text-blue-400" >support@behumane.co.</a></p></div>
                    </div>
                </div>
            </div>
            <div className='mx-0  flex justify-center max-md:order-last max-md:mb-0'>
                <Button buttonText={"Continue chatting"} className='bg-purple-to-pink text-white h-10 w-80 border rounded-xl font-bold text-base' onClick={() => navigate('/')} />
            </div>

            <div className='sticky top-full justify-center hidden max-md:flex'>
                <div className="text-xs text-chat-purple pb-16 max-md:px-0 text-center">© {new Date().getFullYear()} Be Humane(e),co.</div>
            </div>
            <div className="sticky top-full flex justify-between items-center sm:px-9 md:px-20 mb-8">
                <div className="text-xs max-md:px-3 max-md:order-last">
                    <a href="mailto:support@behumane.co" className='text-chat-purple'>Need help ?</a>
                </div>
                <div className="text-xs text-chat-purple p-4 max-md:px-0 absolute left-1/2 transform -translate-x-1/2 max-md:hidden">
                    © {new Date().getFullYear()} Be Humane(e),co.
                </div>
                <div className="hidden text-xs max-md:px-4 max-md:flex max-md:justify-between">
                    <a
                        href="https://www.behumane.ai/privacy-policy"
                        className="text-xs ml-1 text-chat-purple"
                        target="_blank"
                        rel="noreferrer noopener" >
                        Privacy Policy
                    </a>
                </div>
                <div className="text-xs max-md:px-3 max-md:flex max-md:justify-between">
                    <a
                        href="https://www.behumane.ai/terms-and-conditions"
                        className="text-xs text-chat-purple"
                        target="_blank"
                        rel="noreferrer noopener" >
                        Terms & Conditions
                    </a>
                    <a
                        href="https://www.behumane.ai/privacy-policy"
                        className="text-xs ml-1 text-chat-purple max-md:hidden"
                        target="_blank"
                        rel="noreferrer noopener" >
                        Privacy Policy
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ConfirmedPlan
