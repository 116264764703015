const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

const PaymentService = {
  createPaymentIntentAndCustomer: async (email) => {
    try {
      const response = await fetch(`${API_BASE_URL}/payment/create-payment-intent-and-create-customer`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({email :email}),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to create payment intent");
      }
      return data;
    } catch (error) {
      console.error("Error payment intent: ", error);
      return null;
    }
  },
  createSubscription: async (customer_id,subscriptionType,payment_method_id,premium_code) => {
    try {
      const response = await fetch(`${API_BASE_URL}/payment/create-subscription`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customer_id:customer_id, subscription_type:subscriptionType ,payment_method_id:payment_method_id,premium_code:premium_code }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to create subscription ");
      }
      return data;
    } catch (error) {
      return null;
    }

  },
  getSubscriptionDetails: async (subscription_id) => {
     try {
       const response = await fetch(`${API_BASE_URL}/payment/get-subscription-details`, {
         method: "Post",
         credentials: "include",
         headers: {
           "Content-Type": "application/json",
         },
         body: JSON.stringify({subscription_id:subscription_id}),
       });
       const data = await response.json();
       if (!response.ok) {
         throw new Error(data.message || "Failed to fetch messages");
       }
       return data;
     } catch (error) {
       console.error("Error fetching messages: ", error);
     }
   },
  getPremiumCodeStatus: async (premium_code) => {
    try {
      const response = await fetch(`${API_BASE_URL}/payment/get-premium-code-status`, {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({premium_code:premium_code}),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch premium code status ");
      }
      return data;
    } catch (error) {
      console.error("Error fetching premium code status: ", error);
    }
  },
};

export default PaymentService;
