const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

const UserService = {
  getProfile: async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/user/profile`, {
        method: "GET",
        credentials: "include",  
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch profile");
      }
      return data;
    } catch (error) {
      console.error("Error fetching profile: ", error);
      return null; 
    }
  },

  updatePhoneNumber: async (phoneNumber) => {
    try {
      const response = await fetch(`${API_BASE_URL}/user/phone_number`, {
        method: "PUT",
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number: phoneNumber }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to update phone number");
      }
      return data;
    } catch (error) {
      console.error("Error updating phone number: ", error);
      return null;  
    }
  },
  getSignInStatus: async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth/status`, {
        method: "GET",
        credentials: "include",  
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch status");
      }
      return data;
    } catch (error) {
      console.error("Error fetching status: ", error);
      return null; 
    }
  },
};

export default UserService;
